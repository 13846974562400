import axios from "axios";
import pako from "pako";
import secureLocalStorage from "react-secure-storage";

// Create the Axios instance
const apiClient = axios.create({
  withCredentials: true,
  credentials: "same-origin",
  baseURL: process.env.REACT_APP_API_URL, // Update with your API URL
  headers: {
    "Content-Type": "application/json",
    siteID: process.env.REACT_APP_SITE_ID,
  },
});

// Helper function to convert Uint8Array to Base64
function uint8ArrayToBase64(buffer) {
  const binary = [].map.call(buffer, (byte) => String.fromCharCode(byte)).join("");
  return window.btoa(binary);
}

// Function to get encrypted geo data
async function getEncryptedGeoData() {
  try {
    const response = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=4f2cd4648e5f46a8a93a7ee599ecd845");
    const compressedDataUint8 = pako.deflate(JSON.stringify(response.data));
    const base64CompressedData = uint8ArrayToBase64(compressedDataUint8);
    return base64CompressedData;
  } catch (error) {
    console.error("Error fetching geo data:", error);
    return null;
  }
}

// Function to determine page type based on the route
function getPageType() {
  if (window.location.pathname === "/") {
    return "home";
  } else if (window.location.pathname.startsWith("/login")) {
    return "login";
  } else if (window.location.pathname.startsWith("/signup")) {
    return "sign up";
  } else if (window.location.pathname.startsWith("/account/details")) {
    return "Settings";
  } else if (window.location.pathname.startsWith("/reset-password")) {
    return "Reset Password";
  } else if (window.location.pathname.startsWith("/user-reset-password")) {
    return "Reset Password Via Email";
  } else if (window.location.pathname.startsWith("/subscription-plan")) {
    return "Checkout";
  } else if (window.location.pathname.startsWith("/account/subscription")) {
    return "Subscription";
  } else {
    return "unknown";
  }
}

// Function to refresh the access token
async function refreshAccessToken() {
  try {
    const userData = secureLocalStorage.getItem("LogObject");
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
      userID: userData?.userID,
    });

    if (response.data.status && response.data.content.accessToken) {
      secureLocalStorage.setItem("accessToken", response.data.content.accessToken);
      return response.data.content.accessToken;
    } else {
      throw new Error("Failed to refresh access token.");
    }
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error;
  }
}

// Axios request interceptor for handling headers and refreshing tokens
apiClient.interceptors.request.use(
  async (config) => {
    try {
      const accessToken = secureLocalStorage.getItem("accessToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        const newToken = await refreshAccessToken();
        config.headers.Authorization = `Bearer ${newToken}`;
      }

      const performanceData = window.performance.timing;
      const pageLoadTime = performanceData.loadEventEnd - performanceData.navigationStart;
      const domReadyTime = performanceData.domContentLoadedEventEnd - performanceData.navigationStart;
      const utmParams = JSON.parse(secureLocalStorage.getItem("utmParams"));

      const geoData = await getEncryptedGeoData();
      const userData = secureLocalStorage.getItem("LogObject");
      const pageType = getPageType();
      
      config.headers["X-GeoData"] = geoData;
      config.headers["userID"] = userData?.userID;
      config.headers["siteID"] = process.env.REACT_APP_SITE_ID;
      config.headers["pageTitle"] = document.title;
      config.headers["pageURL"] = window.location.href;
      config.headers["pageType"] = pageType;
      config.headers["utmSource"] = utmParams?.utm_source || "";
      config.headers["utmMedium"] = utmParams?.utm_medium || "";
      config.headers["utmCampaign"] = utmParams?.utm_campaign || "";
      config.headers["utmContent"] = utmParams?.utm_content || "";
      config.headers["domReadyTime"] = domReadyTime.toString();
      config.headers["pageLoadTime"] = pageLoadTime.toString();
    } catch (error) {
      console.error("Error setting the request headers:", error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Axios response interceptor to handle token expiry
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshAccessToken();
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default apiClient;
